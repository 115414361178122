






import { Component, Vue } from 'vue-property-decorator';
import NaamVinder from './components/NaamVinder.vue';

@Component({
  components: {
    NaamVinder,
  },
})
export default class App extends Vue {}
